//detail news
.title-blogs-item{
	font-size: 24px;
	color:#011b68;
	line-height: 30px;
}
.detail-post{
	margin-top: 27px;
	img{max-width: 100%;height: auto;}
}
//detail tour
#main_img{
	position: relative;
	padding-top:66.66%;
	.img-main-detail{
		position: absolute;
		top:0;
		bottom:0;
		left:0;
		right: 0;
		height: 100%;
		width: 100%;
		object-fit: contain;
	}
}
.thub-image{
	.item{
		@extend #main_img;
		.img-thumb-product{
			@extend .img-main-detail;
		}
	}
	margin-bottom: 13px;
}
.title-name-product{
	font-size: 24px;
	font-weight:bold;
	color: #011b68;
	line-height: 30px;
	margin-bottom: 20px;
	margin-top: 25px;
}
.view-images-large{margin-bottom: 12px;}
.bottom-detail-product{
	nav{
		background: #ebf4fd;
		.nav-item {
			display: inline-block;
			font-weight: bold;
			width: 25%;
			text-transform: uppercase;
			font-size: 13px;
			padding: 8px 10px;
			img{padding-right: 5px;}
			&.active{
				color:#fff;
			}
		}
	}
	.tab-content{margin-top: 30px;margin-bottom: 50px}
}
.info-tour{
	.title-detail-tour{
		background-color: #011b68;
		color: #fff;
		font-weight: bold;
		font-size:14px;
		padding: 23px 18px;
		margin-bottom: 0;
		line-height: 22px;
	}
	.list-info-tour{
		margin-bottom: 30px;
		padding: 25px;
	    list-style: none;
	    background: #f7f7f7 url(/uploads/plugin/custom_img/2019-11-07/1573066313-2503-custom.png) center center no-repeat;
	    background-size: contain;
	    li{
	    	border-bottom: 1px dashed #7f96d7;
		    padding-bottom: 12px;
		    margin-bottom: 14px;
		    span.inner-infor-tour{
		    	color: #0072b6;
			    font-size: 15px;
			    font-weight: bold;		    }
	    	span{
	    		color:#252525;
	    		font-weight: normal;
	    		width: 145px;
	    		display: inline-block;
	    		vertical-align: middle;
	    	}
	    	&:last-child{
	    		border-bottom: 0;
	    		margin-bottom: 0;
	    		padding-bottom: 0;
	    	}
	    }
	}
}
.wrp-booking{
	background: #0073e9;
	padding: 22px 34px;
	margin-bottom: 15px;
	.btn-booking{
		display: block;
		background:#011b68;
		color:#fff !important;
		font-weight:bold;
		padding: 0;
		height: 40px;
		line-height: 40px;
	}
	.price-detail-page {
		span{
			color:#fff;
			font-size: 24px;
			font-weight:bold;
			&.price-before{
				text-decoration: line-through;
				font-size: 18px;
				color:#ddd;
				font-weight:normal;
			}
		}
	}
	.slogan-booking-detail-price{
		margin-top: 4px;
		color:#aad4ff;
	}
	.date-booking{
		color:#909090;
		background-color:#ffffff;
		line-height: 38px;
		height:38px;
		text-align: left;
		padding: 0 15px;
	}
}

//Member
.display-theme-1{display: none;}
.display-theme-2{
	float:right;
	margin-top: 23px;
	a{
		color:#fff;
		padding: 0 5px;
		text-transform: uppercase;
		&:last-child{
			border-left: 1px solid #fff;
			padding-left: 9px; 
		}
	}
}
.btn-round {
    border-width: 1px;
    border-radius: 30px !important;
}
.card{
	border: 0;
    margin-bottom: 30px;
    margin-top: 30px;
    border-radius: 6px;
    color: rgba(0,0,0,.87);
    background: #fff;
    width: 100%;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
    .card-header-primary{
    	box-shadow: 0 5px 20px 0 rgba(0,0,0,.2), 0 13px 24px -11px rgba(156,39,176,.6);
    	background: linear-gradient(60deg,#ab47bc,#7b1fa2);
    }
}
.card-header {
	padding: 20px 0;
	.card-title{
		color:#fff;
		font-weight: bold;
	}
}  
.card-footer{
	background-color: transparent;
}
.fa-asterisk{font-size:10px !important; color:red;}
.account-user{
	float: right;
	.wrap-avatar-member{
		color:#fff;
		font-size: 18px;
		margin-top: 18px;
		margin-right: 5px;
	}
	.menu-list{
		right: 0px !important;
	    left: auto !important;
	    border-radius: 0;
	    padding: 10px 15px;
	    min-width: 200px;
	    li{
	    	margin-bottom: 7px;
	    	a{
		    	color:#333;
		    	i,span{
		    		padding-right: 3px;
		    	}
		    }
		    &:last-child{margin-bottom: 0;}
	    }
	    
	}
}